import { ILevel } from "../../Model/Level";
import { IInstitutionOption } from "../../Model/InstitutionOption";
import { INotificationAdmin } from "../../Model/NotificationAdmin";
import { buildTargetPermissionsResult, Permission } from "../modules/authorization/permission";
import { IAdmin } from "../../Model/Admin";

enum ENotificationAdminName {
    notif1 = "notif1",
    notif2 = "notif2",
    notif3 = "notif3",
    notif4 = "notif4",
    notif5 = "notif5",
    notif6 = "notif6",
    notif7 = "notif7",
    notif8 = "notif8",
    notif9 = "notif9",
    notif10 = "notif10",
    notif11 = "notif11",
    notif12 = "notif12",
    notif13 = "notif13",
    notif14 = "notif14",
    notif15 = "notif15",
    notif16 = "notif16",
    notif17 = "notif17",
    notif18 = "notif18",
    notif19 = "notif19",
    notif20 = "notif20",
    notif21 = "notif21",
    notif22 = "notif22"
}

interface IPropsNotificationOptions {
    value: number;
    label: string;
}

export interface IProNotification {
    name: string;
    label: string;
    defaultValue: number;
    category: number;
    position: number;
    hidden?: boolean;
    options: IPropsNotificationOptions[];
}

// Category 1 : Remplacent
// Category 2 : Admin
// Category 3 : Mission
// Category 4 : Other
// Position will only affect the current category.
function getProNotifications({
    permissions,
    institutionOptions = { canValidateMission: false },
    lang = {},
    me
}: {
    permissions: ILevel;
    institutionOptions: any | IInstitutionOption;
    lang?: any;
    me?: IAdmin;
}): IProNotification[] {
    const userPermissions = buildTargetPermissionsResult({
        targetPermissions: [
            Permission.REQUEST_INTERIM_AGENCY_OR_MARKETPLACE,
            Permission.CANCEL_MISSION_AFTER_VALIDATION,
            Permission.VALIDATE_MISSION_REQUEST_WITHOUT_WORKER,
            Permission.VALIDATE_MISSION_REQUEST_WITH_WORKER
        ],
        userPermissions: me?.permissions,
        institutionIds: me?.institutionIds
    });

    return [
        {
            category: 1,
            defaultValue: 0,
            label: lang.isInvited,
            name: ENotificationAdminName.notif1,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 1
        },
        {
            category: 1,
            defaultValue: 2,
            label: lang.subscribes,
            name: ENotificationAdminName.notif2,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.never,
                    value: 0
                },
                {
                    label: lang.onlyIInvited,
                    value: 2
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                }
            ],
            position: 2
        },
        {
            category: 1,
            defaultValue: 0,
            label: lang.hasSpecialtiesModified,
            name: ENotificationAdminName.notif3,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 4
        },
        {
            category: 1,
            defaultValue: 0,
            label: lang.isDeletedFromNetwork,
            name: ENotificationAdminName.notif4,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 8
        },
        {
            category: 2,
            defaultValue: 0,
            label: lang.createsNewMission,
            name: ENotificationAdminName.notif5,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 1
        },
        {
            category: 5,
            defaultValue: 2,
            label: lang.applyStandardMission,
            name: ENotificationAdminName.notif6,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                }
            ],
            position: 1
        },
        {
            category: 5,
            defaultValue: 2,
            label: lang.applyExpressMission,
            name: ENotificationAdminName.notif7,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                }
            ],
            position: 2
        },
        {
            category: 2,
            defaultValue: 0,
            label: lang.assignStandardMission,
            name: ENotificationAdminName.notif8,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 3
        },
        {
            category: 2,
            defaultValue: 2,
            label: lang.cancelMission,
            name: ENotificationAdminName.notif9,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                },
                {
                    label: lang.onlyMyMissionCanceledOtherAdmin,
                    value: 2
                }
            ],
            position: 4
        },
        {
            category: 2,
            defaultValue: 1,
            hidden:
                !userPermissions[Permission.VALIDATE_MISSION_REQUEST_WITH_WORKER] &&
                !userPermissions[Permission.VALIDATE_MISSION_REQUEST_WITHOUT_WORKER],
            label: lang.createsMissionRequest,
            name: ENotificationAdminName.notif10,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 2
        },
        {
            category: 1,
            defaultValue: 3,
            label: lang.askedNewSpecialties,
            name: ENotificationAdminName.notif11,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 3
        },
        {
            category: 3,
            defaultValue: 0,
            hidden:
                !userPermissions[Permission.CANCEL_MISSION_AFTER_VALIDATION] ||
                (institutionOptions && !institutionOptions.canValidateMission),
            label: lang.wasValidated,
            name: ENotificationAdminName.notif12,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 4
        },
        {
            category: 3,
            defaultValue: 2,
            label: lang.isPending,
            name: ENotificationAdminName.notif13,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 1
        },
        {
            category: 3,
            defaultValue: institutionOptions && institutionOptions.canValidateMission ? 2 : 0,
            hidden:
                !permissions.isAllowedToValidateMission ||
                (institutionOptions && !institutionOptions.canValidateMission),
            label: lang.mustBeValidated,
            name: ENotificationAdminName.notif14,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 3
        },
        {
            category: 5,
            defaultValue: 3,
            label: lang.hasMissionOnDifferentService,
            name: ENotificationAdminName.notif15,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 3
        },
        {
            category: 4,
            defaultValue: 2,
            label: lang.allCandidatesWarned,
            name: ENotificationAdminName.notif17,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 1
        },
        {
            category: 4,
            defaultValue: 3,
            hidden: !userPermissions?.[Permission.REQUEST_INTERIM_AGENCY_OR_MARKETPLACE],
            label: lang.adminWantsInterim,
            name: ENotificationAdminName.notif18,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 2
        },
        {
            category: 3,
            defaultValue: 2,
            label: lang.wasModified,
            name: ENotificationAdminName.notif19,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 2
        },
        {
            category: 4,
            defaultValue: 2,
            hidden: !userPermissions?.[Permission.REQUEST_INTERIM_AGENCY_OR_MARKETPLACE],
            label: lang.agencySetInterim,
            name: ENotificationAdminName.notif20,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyMyMission,
                    value: 2
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 3
        },
        {
            category: 2,
            defaultValue: 0,
            label: lang.declareFutureMission,
            name: ENotificationAdminName.notif21,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 5
        },
        {
            category: 2,
            defaultValue: 0,
            label: lang.declarePastMission,
            name: ENotificationAdminName.notif22,
            options: [
                {
                    label: lang.allTheTime,
                    value: 1
                },
                {
                    label: lang.onlyConcernMyServices,
                    value: 3
                },
                {
                    label: lang.never,
                    value: 0
                }
            ],
            position: 6
        }
    ];
}

export function getDefaultNotificationAdmin(): INotificationAdmin {
    return {
        id: 0,
        notif1: 0,
        notif2: 2,
        notif3: 0,
        notif4: 0,
        notif5: 0,
        notif6: 2,
        notif7: 2,
        notif8: 0,
        notif9: 2,
        notif10: 1,
        notif11: 3,
        notif12: 0,
        notif13: 2,
        notif14: 2,
        notif15: 3,
        notif17: 2,
        notif18: 3,
        notif19: 2,
        notif20: 2,
        notif21: 0,
        notif22: 0
    };
}

export { getProNotifications, ENotificationAdminName };
