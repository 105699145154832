import { ILang } from "./Lang";

/* eslint-disable @typescript-eslint/camelcase */
export const en: ILang = {
    isCvMandatory: "diploma mandatory EN",
    isDiplomaMandatory: "diploma mandatory EN",
    hasHubloPool: "is an hublo pool client DE",
    no: "No",
    yes: "Yes",
    modifier: "Modify",
    institution: "institution",
    institutiongroup: "institution group",
    schedule: "time code",
    service: "service",
    job: "job",
    missionMotifCreation: "reason",
    specialty: "specialty",
    admin: "admin",
    infoOnWorker: "info Supp",
    notification: "notification",
    contract: "contract",
    salaryGrid: "bonus grid",
    institutionChronos: "gta Chronos",
    gtaOctime: "gta Octime",
    interim: "interim",
    specialHour: "special hours",
    institutionPlural: "institutions",
    institutiongroupPlural: "groups",
    schedulePlural: "time codes",
    servicePlural: "services",
    jobPlural: "jobs",
    missionMotifCreationPlural: "patterns",
    specialtyPlural: "specialties",
    adminPlural: "admins",
    infoOnWorkerPlural: "info Supp",
    notificationPlural: "notifications",
    contractPlural: "contracts",
    salaryGridPlural: "bonus grids",
    institutionChronosPlural: "gta Chronos",
    gtaOctimePlural: "gta Octime",
    interimPlural: "intérims",
    specialHourPlural: "special hours",
    institutionNone: "No institution",
    institutiongroupNone: "No grouping",
    scheduleNone: "No time code",
    serviceNone: "No service",
    jobNone: "No job",
    missionMotifCreationNone: "No reason",
    specialtyNone: "No specialty",
    motifNone: "No motif",
    adminNone: "No admin",
    infoOnWorkerNone: "No additional info",
    notificationNone: "No notification",
    contractNone: "No contract",
    salaryGridNone: "No bonus grid",
    institutionChronosNone: "No GTA Chronos",
    gtaOctimeNone: "No GTA Octime",
    interimNone: "No interim",
    specialHourNone: "No special hours",
    actions: "actions",
    add: "add",
    next: "next",
    previous: "previous",
    new: "new",
    return: "return",
    confirm: "Confirm",
    select: "Select",
    date: "Date",
    action: "Action",
    success: "Success",
    here: "here",
    sign: "sign",
    mission: "Mission",
    proMissions: "my missions",
    proVacataires: "my network",
    proContracts: "my contracts",
    dashboard: "dashboard",
    redirectToLocalhost: "Redirect to localhost",
    profile: "My account",
    myInstitutions: "my institutions",
    myNotifications: "my notifications",
    myConfig: "parameterization",
    logout: "disconnection",
    title: "Back office - Hublo",
    addLine: "Line successfully added",
    modifyLine: "Line successfully modified",
    errorLine: "Line not added because an error occurred",
    errCodeGeneric520: "An error has occurred. Please contact support",
    errCodeGeneric500: "An error occurred, please try again",
    errCodeGeneric400: "Please check your settings",
    errCodeGeneric404: "The information you are looking for cannot be found",
    errCodeGeneric401: "Please login",
    errCodeGeneric403: "You are not authorized to perform this action",
    errCodeGeneric409: "Name already existing",
    errCodeGeneric406: "Incorrect value",
    errCodeGeneric4062: "Invalid information",
    errCodeGeneric419: "Required field",
    errCodeGeneric449: "Admin already created",
    typeHelper: "1 : default",
    contractLevelHelper: "1: Hublo, 2: can download contracts, 3: cannot download contracts",
    recapTypeHelper:
        "0: no recap, 1: recap sent daily for contracts per mission, 2: monthly recap for contracts per replacement, 3: DPAE",
    nbMinutesAfterLaunchingRequestHelper:
        "Time between activation of the interim and sending to the agencies (in minutes)",
    seniorityShouldBeLessThanHelper: "Included",
    seniorityShouldBeMoreThanHelper: "Excluded",
    interfacingCodeHelper: "Click here to be redirected to the FAQ",
    id: "id",
    name: "name",
    firstName: "First name",
    lastName: "Name",
    email: "E-mail address",
    mobilePhone: "Phone number",
    level: "level",
    contractLevel: "contract",
    isPlanningAllowed: "planning",
    isStatisticAllowed: "statistics",
    shouldSendEmail: "sending an invitation",
    typeAdmin: "admin type",
    type: "type",
    typeName: "type",
    code: "name",
    "code-form": "Code name",
    codeUF: "code UF",
    beginAt: "start",
    url: "URL",
    isProxyRequired: "proxy",
    token: "token",
    password: "password",
    hasSecurityModule: "security module",
    endAt: "end",
    nbWorkedMinutes: "working time",
    interfacingCode: "Interface Code",
    nameSMS: "SMS Name",
    pole: "pole",
    interfacingCode2: "interface Code 2",
    interfacingCode3: "interface Code 3",
    isNameAbsentPersonRequired: "info required",
    nameAbsentPersonPlaceholderInfo: "placeholder",
    isFullTime: "full time",
    nbMinutesAfterLaunchingRequest: "Triggering (minutes)",
    hasAutoValidation: "Automatic Validation",
    isDayMission: "day mission?",
    isPublicHoliday: "public holiday mission?",
    isSunday: "mission on Sunday?",
    seniorityShouldBeLessThan: "minimum seniority required",
    seniorityShouldBeMoreThan: "maximum seniority required",
    services: "departments",
    jobs: "jobs",
    codeAPI: "API code",
    user: "user",
    loginKey: "login key",
    isVersionGreaterOrEqualTo92: "version >= 9.2",
    clientName: "client",
    societe: "company",
    webrest: "webrest",
    codeSociete: "company code",
    codeEtablissement: "institution code",
    codeService: "service code",
    codeInterface: "interface code",
    jobName: "job",
    specialtyName: "specialty",
    poleName: "pole",
    recapType: "summary type",
    to: "to",
    cc: "cc",
    bcc: "bcc",
    break: "break",
    "break-form": "unpaid break",
    searchPlaceholder_admin: "Search for an administrator with a name, email, phone",
    searchPlaceholder_adminV2: "Search for an administrator with a name, email, phone, or registration number",
    searchPlaceholder_schedule: "Search for a time code with a name, schedule or working time",
    add_schedule: "Add a time code",
    add_service: "Add a service",
    add_job: "Add a job",
    add_missionMotifCreation: "Add a reason",
    add_specialty: "Add a specialty",
    add_admin: "Add an admin",
    add_adminV2: "Add an administrator",
    add_infoOnWorker: "Add a new info",
    add_notification: "Add a notification",
    add_salaryGrid: "Add a bonus grid",
    add_interim: "Add an agency",
    add_title_interim: "Add a sourcing agency",
    add_specialHour: "Add a special hour",
    add_contract: "Add a contract",
    modify_schedule: "Edit a time code",
    modify_service: "Modify a service",
    modify_job: "Modify a job",
    modify_missionMotifCreation: "Modify a reason",
    modify_specialty: "Modify a specialty",
    modify_admin: "Modify an admin",
    modify_infoOnWorker: "Modify an info supp",
    modify_notification: "Modify a notification",
    modify_salaryGrid: "Modify a bonus grid",
    modify_interim: "Modify a temp",
    modify_specialHour: "Modify a special hour",
    modify_contract: "Modify a contract",
    create: "Create",
    save: "Save",
    cancel: "Cancel",
    notAllInsertHaveBeenWellDone: "Not all insertions have been well done",
    counter: "Counters and Meters",
    counterStatus: "counters",
    counterLimit: "blocking limit",
    isActive: "Status",
    whichCounter: "Counter type",
    counterLimitType: "Type EN",
    starter: "Start of the counter",
    export: "Export",
    chooseTypeOfCounterForYourInstitution: "Choose the types of accessible time/shifts meters for your institution.",
    onceLimitReachedWorkersWontBeAlerted:
        "Once the limit is reached, replacements are no longer alerted and can no longer apply.",
    workerType: "Types of workers",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    yearlyBaseOnJoiningDate: "Yearly - Based on joining date",
    weeklyH: "Weekly hours",
    monthlyH: "Monthly hours",
    yearlyH: "Yearly hours",
    yearlyJoinDateH: "Yearly(on join date) hours",
    weeklyShifts: "Weekly shifts",
    monthlyShifts: "Monthly shifts",
    yearlyShifts: "Yearly shifts",
    yearlyJoinDateShifts: "Yearly(on join date) shifts",
    weeklyStarter: "Every __DAY",
    monthlyStarter: "Every __DAY of the month",
    yearlyStarter: "Every __DAY",
    yearlyBaseOnJoiningDateStarter: "joining date",
    salarie: "Employee/Agent",
    vacataire: "External temp",
    schedulesHaveBeenCreated: "Your time codes have been created!",
    scheduleHasBeenCreated: "Your time code has been created!",
    scheduleHasBeenUpdated: "Your time code has been updated!",
    confirmationSubtitle_schedule: "Are you sure you want to edit this time code?",
    confirmationText_schedule:
        "You currently have __MISSIONS_COUNT upcoming shift with this time code. Editing the time code will not impact these shifts. If necessary, you can edit the schedules of these shifts once they have been filled.",
    confirmationText_schedule_plural:
        "You currently have __MISSIONS_COUNT upcoming shifts with this time code. Editing the time code will not impact these shifts. If necessary, you can edit the schedules of these shifts once they have been filled.",
    yesApply: "Confirm",
    noDontApply: "Cancel",
    enabled: "Enabled",
    disabled: "Disabled",
    deleteConfirmationSubtitle_schedule: "Are you sure you want to delete that time code?",
    deleteConfirmationText_schedule: "It will not affect your missions already created with this time code.",
    deleteActionCompletedConfirmationMessage_schedule: "Your time code has been deleted!",
    rowsPerPageText: "Lines per page",
    rangeSeparatorText: "of",
    areYouSure: "Are you sure ?",
    deleted: "Deleted !",
    noResult: "No Result",
    exportMissionThenAttribute: "Export missions and assign payroll codes",
    lastExportOnXXXByXXX: "Last export on __VAR1 by __VAR2",
    payCode: "Payroll codes",
    searchPayCode: "Search for a pay code",
    addPayCode: "Add a payroll code",
    internalCode: "Internal code",
    externalCode: "External code",
    businessDay: "Business day",
    night: "Night",
    sundayAndHoliday: "Sunday and holiday night watch",
    rangeOfTotal: "__VAR1 of __VAR2",
    simpleCode: "Code",
    editPayCode: "Edit a payroll code",
    deletePayCode: "Delete a payroll code",
    askForDeletingPayCode: "Are you sure you want to delete the pay code?",
    delete: "Delete",
    payCodeWellCreated: "The payroll code has been created!",
    payCodeWellChanged: "The payroll code has been changed!",
    payCodeWellDeleted: "The payroll code has been deleted!",
    payrollExport: "Payroll export",
    sso: "SSO",
    ssoPageTitle: "Configure SSO (Single Sign-On)",
    missionExportAndPayrollCodeConfiguration: "Export assignments and assign payroll codes",
    exportConfiguration: "Export configuration",
    publicHolidays: "Public Holidays",
    add_exportConfiguration: "Add a payroll code",
    searchPlaceholder_exportConfiguration: "Search for a payroll code",
    isNight: "Night",
    isBusinessDay: "Business day",
    isBeforeSundayOrPublicHoliday: "Sunday or Public Holiday Eve Night",
    deleteConfirmationSubtitle_exportConfiguration: "Delete a payroll code",
    deleteConfirmationText_exportConfiguration: "Are you sure you want to delete the pay code?",
    modify_exportConfiguration: "Edit a payroll code",
    deleteActionCompletedConfirmationMessage_exportConfiguration: "The payroll code has been deleted!",
    exportConfigurationHasBeenUpdated: "The payroll code has been changed!",
    exportConfigurationHasBeenCreated: "The payroll code has been created!",
    exportConfigurationsHaveBeenCreated: "The payroll codes have been created!",
    confirmationSubtitle_exportConfiguration: "Are you sure you want to edit the payroll code?",
    youShouldPreciseInstitutionName: "You must enter a name for your institution",
    yourAddressAreNotRecognized:
        "Your address has not been recognized. Start typing your address and select your address from the results offered. If your address does not appear, select an address near yours.",
    youShouldIndicateValidAddress: "You must select a valid address",
    pleaseFillYourAddressAndSelectAChoice: "Please enter your address and select from the list of proposed addresses",
    youHaveQuestion: "Have a question?",
    usefullInformations: "Usefull information",
    institutionName: "Institution's name",
    visibleMobileForWorkers: "Phone can be viewed by locums",
    institutionAddress: "Institution's address",
    timeCodePlural: "Time codes",
    subtitleJobs: "Job",
    subtitleServices: "Departments",
    speciaties: "Specialities",
    toModifyServiceListOrTimeCode:
        "To modify the list of departments, jobs, specialities or time codes, please contact us at",
    institutionPresentation: "Institution presentation",
    accessInformation: "Access information",
    dateInput: "DD/MM/YYYY",
    publicholidayConfiguration: "Export configuration",
    add_publicholidayConfiguration: "Add a holiday",
    searchPlaceholder_publicholidayConfiguration: "Search for a holiday",
    deleteConfirmationSubtitle_publicholidayConfiguration: "Delete a public holiday",
    deleteConfirmationText_publicholidayConfiguration:
        "Are you sure you want to eliminate __PUBLIC__HOLIDAY, as a statutory holiday from your institution?",
    modify_publicholidayConfiguration: "Edit a public holiday",
    deleteActionCompletedConfirmationMessage_publicholidayConfiguration: "The public holiday has been deleted!",
    publicholidayConfigurationHasBeenUpdated: "The public holiday has been changed!",
    publicholidayConfigurationHasBeenCreated: "The public holiday has been created!",
    publicholidayConfigurationsHaveBeenCreated: "The public holidays have been created!",
    confirmationSubtitle_publicholidayConfiguration: "Are you sure you want to edit the public holiday?",
    addedPublicHolidayAlreadyExistsInTheInstitution: "The added holiday already exists in your institution.",
    addedPublicHolidayAlreadyExistsInOneOfTheSelectedInstitutions:
        "The added holiday already exists in one or more of the selected establishments.",
    addedPublicHolidaysAlreadyExistsInTheInstitution: "One or more added days already exist in your establishment.",
    addedPublicHolidaysAlreadyExistsInTheInstitutions:
        "One or more of the added days already exist in one or more of the selected institutions.",
    noLastExport: "No exports have been made yet!",
    anEmailWillBeSentToXXX: "The export was sent to ___VAR1",
    documentTemplate: "Document template",
    import: "Import",
    wrongFileFormat: "Your file is not in the right format",
    wrongFileFormatUseTemplate: "Your document is not in the format required. Please use the downloadable template.",
    onlyAcceptCSV:
        "We only accept files in .csv format.\nPlease make the appropriate changes and import your document again.",
    fileTooLarge: "Your file is too large",
    maxCSVSizeIs:
        "The maximum accepted file size is 100Ko.\nPlease make the appropriate changes and re-import your document.",
    anErrorOccured: "An error has occurred",
    askRetryImport: "Please import your document again or contact our support.",
    errorInDocument: "There is an error in your document.",
    askCorrectDocument:
        "Errors have crept into your file and prevent us from being able to update your time codes.\nPlease make the corrections and import your document again or contact our support.",
    confirmationImport_schedule: "Are you sure you want to import these time codes?",
    treatment_schedule: "New time codes will be created and existing time codes will be updated.",
    updated_schedule: "Your time codes have been updated!",
    errorInCSV_schedule:
        "An error has occurred in your file and prevents us from updating your time codes: __ERROR_MESSAGE__\nPlease correct and reimport your document or contact our support.",
    missingField: "__NAME__ does not have all required fields completed.",
    invalidStartDate: "__NAME__ has an invalid start time value.",
    invalidEndDate: "__NAME__ has an invalid end time value.",
    invalidPauseTime: "__NAME__ has an invalid break time value.",
    invalidWorkingTime: "__NAME__ has an invalid work time value.",

    youWantAddTimeCode: "Do you want to add a time code?",
    beCareful: "Warning",
    modificationNotSaved: "You have not saved your modifications, do you wish to continue?",
    continue: "Continue",
    writeNotesOrQuestions: "Send us your comments or questions",
    addTimeCodeToCreateMission: "Add a time code to create a shift",
    contactUsAtAddress: "Contact us at the address ",
    orAskToBeContacted: " or request to be recontacted.",
    askToBeRecontacted: "Request to be recontacted",
    WhichTimeCodeYouWantAdd:
        "Which time codes do you want to add? Please specify for each code the name of the code, the start time, the end time, the duration actually worked without break time.",
    placeholderNameSchedule: "ex: AJF19",
    placeholderInterfacingCodeSchedule: "ex: AV205",
    presentation: "Presentation",
    address: "Address",
    postalCode: "",
    modificationsSaved: "The modifications have been saved",
    accessInfo: "Access information",
    verticalMenuTitle: "Parameters of the establishment",
    templateName: "__NAME___Template.csv",
    exportName: "__NAME___Export.csv",
    scheduleCamelCase: "TimesCodes",
    noInternetConnexion: "No Internet connection",
    serviceCamelCase: "Services",
    contractDemo: "⚡ My contracts",
    specialHourCamelCase: "HeuresSpéciales",
    infoOnWorkerCamelCase: "InfosSupp",
    salaryGridCamelCase: "GrilleDePrimes",
    institutionChronosCamelCase: "GtaChronos",
    adminCamelCase: "Admins",
    gtaOctimeCamelCase: "GtaOctime",
    importInProgress: "Import in progress",
    importingPleaseWait: "Processing of your file in progress. Please wait a moment.",
    missionMotifCreationCamelCase: "Motifs",
    interimCamelCase: "Interim",
    contractCamelCase: "Contracts",
    notificationCamelCase: "Notifications",
    prefixMission: "Mission Prefix",
    phone: "Phone",
    siret: "Siret",
    corporateName: "Legal Name",
    healthService: "Health Service",
    urssafCode: "Code urssaf",
    apeCode: "Code ape",
    activateSso: "Enable Single Sign-On",
    ssoViaOAuth2: "SSO using OAuth2",
    clientId: "Client ID",
    clientSecret: "Client secret",
    authUrl: "Auth URL",
    accessTokenUrl: "Access Token URL",
    userInfoUrl: "User info URL",
    scope: "Scope",
    resource: "Resource",
    responseType: "Response Type",
    usePKCE: "Use Proof Key for Code Exchange extension",
    auth2FormClientIdRequired: "Please specify the clientId of your identity provider",
    auth2FormAuthUrlRequired: "Please specify the url used for the authentication process",
    auth2FormAccessTokenUrlRequired: "Please specify the url used to get access tokens",
    ssoName: "SSO Name",
    oauth2ConfigSaveError: "An error occured while saving oauth2 settings",
    oauth2ConfigSaveSuccess: "Settings saved!",
    myProfile: "My profile",
    phoneNumber: "Mobile phone number",
    toChangeMailContactUs: "To change your email, contact us at  _CONSTANTS_EMAIL_CONTACT_",
    changeMyPassword: "Change my password",
    actualPassword: "Current password",
    newPassword: "New password",
    confirmationPassword: "Confirm new password",
    language: "Language",
    confirmationCounterStatusTitle: "Are you sure you want to edit this time or shift meter?",
    confirmationCounterStatusText: "The time or shift limits for all your future shifts will be recalculated.",
    searchPlaceholder_service: "Search for a service",
    searchPlaceholder_specialty: "Search for a speciality",
    searchPlaceholder_job: "Search for a job",
    searchPlaceholder_missionMotifCreation: "Search for a motif",
    searchPlaceholder_infoOnWorker: "Search for additional information",
    identifyOrPasswordIncorrect: "Your username or password is incorrect",
    institutionInfo: "Institution Info",
    hubSpotId: "hubSpotId",
    hubSpotInvoiceId: "hubSpotInvoiceId",
    institutionOptionContract: "Institution Option Contract",
    coefficientValue: "coefficient value",
    hasDistinctionBetweenPartTimeAndFullTime: "Part-time/full-time contract frame",
    institutionOptionChurn: "institution Option Churn",
    isChurn: "Churn",
    firstNameTooShort: "The first name is too short",
    lastNameTooShort: "The last name is too short",
    institutionOptionMission: "Institution Option Mission",
    hasPool: "Pool",
    institutionOptionWorker: "Institution Option Worker",
    canSelectOneWorkerForMissionRequestCreate: "Proposal of worker for a need of worker for a need",
    hasDoubleValidation: "Double validation",
    canSelectBetweenRecupAndSupp: "Choice between recuperation and overtime",
    institutionOption: "Option of the institution",
    isAddressRequired: "Address required",
    isSocialSecurityNumberRequired: "Social security number",
    hasDPAE: "DPAE",
    isKorian: "Korian",
    isHCL: "HCL",
    hasContract: "Contract",
    isMedgoDemo: "Demo Hublo",
    hasInterim: "Interim",
    hasUnspecifiedService: "Unspecified service",
    hasSMS: "SMS",
    canPostMissionWithoutSchedule: "Mission without time code",
    canSeeTotalCostForInstitution: "See the total cost of the mission",
    canValidateMission: "Being able to validate missions",
    jobCamelCase: "Jobs",
    specialtyCamelCase: "Specialties",
    country: "Country",
    modify_institution: "Modify the institution",
    modify_institutionOption: "Modify the option of the institution",
    modify_institutionOptionWorker: "Modify an option",
    modify_institutionOptionMission: "Modify an option",
    modify_institutionOptionContract: "Modify an option",
    add_institutionOptionMission: "Add an option",
    add_institutionOptionWorker: "Add an option",
    add_institutionOptionContract: "Add an option",
    eSignature: "e-signature",
    eSignatureProvider: "e-signature provider",
    HasMassEsignatureEtab: "Mass signature",
    HasMultiReplacementContract: "Multi replacement contract",
    healthPass: "Health Pass",
    adminV2: "Administrators",
    status: "status",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    getBackToAdminV2List: "Return to the list of administrators",
    lang: "language",
    matricule: "registration number",
    contactInformation: "Contact information",
    levelOfAuthorityAndAssignment: "Level and assignments",
    documentsTemplates: "Document templates",
    shiftSummaryTemplate: "Shift summary template",
    noTemplateUploaded: "No template uploaded",
    submit: "submit",
    upload: "upload",
    selectAFile: "select a file",
    noFileSelected: "no file selected",
    fileUploadSuccess: "Your file has been uploaded!",
    confirmationSubtitle_admin_insert: "Addition of the admin to the institution",
    confirmationText_admin_insert:
        "One of the admins you want to create already exists in another institution. We will only add a new institution to it without changing its information. Do you want to continue?",
    placeholderFirstNameAdmin: "e.g.: Lucas",
    placeholderLastNameAdmin: "e.g.: NAS",
    placeholderEmailAdmin: "e.g.: lucas.nash@hublo.com",
    placeholderMobilePhoneAdmin: "e.g.: 077 0000 0000",
    placeholderMatriculeAdmin: "e.g.: X00000",
    none: "None",
    assignmentInstitutions: "assignment institution(s)",
    position: "job",
    notificationPoles: "notification pole(s)",
    notificationServices: "notification department(s)",
    requiredFields: "required fields",
    assignmentInstitutions_customText: "institution(s)",
    notificationPoles_customText: "pole(s)",
    notificationServices_customText: "department(s)",
    confirmationTitle_deactivate_admin: "Are you sure to deactivate this license?",
    confirmationTitle_activate_admin: "Are you sure you want to activate this license?",
    confirmationText_deactivate_admin: "The administrator will no longer be allowed to use the platform.",
    confirmationText_activate_admin: "The administrator will be notified about the activation of his account.",
    notification_deactivate_admin: "Your administrator has been deactivated!",
    notification_activate_admin: "Your administrator has been activated!",
    specialties: "Skills",
    subtitleSpecialties: "Skills",
    agencyName: "Name of the agency",
    placeholderAgencyName: "ex : Interim System",
    placeholderAgencyEmail: "ex : interim@example.com",
    placeholderNbMinutesAfterLaunchingRequest: "3",
    placeholderAgencyJob: "Select",
    placeholderAgencySpecialty: "Select",
    adminHasNotBeenAdded: "The admin has not been added to the institution",
    adminV2HasBeenCreated: "Your administrator has been created!",
    adminV2HasBeenUpdated: "Your administrator has been edited!",
    sector: "Sector",
    numberOfActiveAdministrators: "Number of active administrators:",
    noResult_adminV2: "No match found",
    confirmationSubtitle_admin_update: "This admin is already using Hublo!",
    confirmationText_admin_update:
        "Sorry, this email already exists in our database. Use the add admin to link the admin to your institution.",
    since: "since",
    deactivateTheLicense: "Deactivate the license",
    isOnForCreatingMission: "Selectable service",
    isDeleted: "Selectable time code",
    doYouWantToDeactivateAdminForAllInstitutions:
        "Do you want to deactivate this administrator for all the group's institutions?",
    ifYouClickNoAdminWillBeDeactivatedOnlyOnThisInstitution: `If you click "No", the administrator will be deactivated only on this institution.`,
    defaultPlaceholder: "Select...",
    manageNotifications: "Manage my Notifications",
    notifAboutWorker: "Notifications concerning my network of locums",
    wantNotifWhenWorker: " I want to be notified when a locum...",
    notifAboutMissions: "Notifications concerning my shifts",
    wantNotifWhenAdmin: "I want to be notified when an administrator..",
    wantNotifWhenMission: "I want to be notified when a shift...",
    wantNotifWhen: " I want to be notified when...",
    listServicesWantNotif: "Here is the list of departments for which I want notifications",
    myServices: "My departments",
    isInvited: "... is invited",
    subscribes: "... subscribes",
    isDeletedFromNetwork: "... is deleted from the newtork",
    hasSpecialtiesModified: "... has modified specialities",
    createsNewMission: "... creates a new shift",
    createsMissionRequest: "... creates a new shift request",
    applyStandardMission: "... applies a Standard shift",
    applyExpressMission: "... applies an Express shift",
    assignStandardMission: "... assigns a Standard shift to an applicant",
    cancelMission: "... cancel a shift",
    declareFutureMission: "... assigns a future shift to an applicant",
    declarePastMission: "... assigns a past shift to an applicant",
    askedNewSpecialties: "... requested new specialities",
    wasValidated: "... has been validated",
    wasModified: "... has been modified",
    isPending: "... is pending",
    mustBeValidated: "... must be valifated",
    hasMissionOnDifferentService: "... has a shift in a different department from the usual department",
    allCandidatesWarned: "... all applicants for a shift have been alerted",
    adminWantsInterim: "... when an administrator wants to solicit the Temp for a shift",
    agencySetInterim: "... when an agency suggests a Temporary person for a shift",
    allTheTime: "Always",
    onlyConcernMyServices: "Only if it concerns my departments",
    onlyIInvited: "Only if I am the one invited",
    onlyMyMission: "Only if I am the one who created the shift",
    onlyMyMissionCanceledOtherAdmin: "Only if I posted the shift and it was canceled by another administrator",
    never: "Never",
    accessNotifications: "Access to notifications",
    hour: "Hour",
    shift: "Mission EN",
    idSubscription: "idSubscription",
    "idSubscription-form": "idSubscription",
    agencyClientId: "Agency ClientId",
    "agencyClientId-form": "Agency ClientId",
    subscriptionName: "Label",
    "subscriptionName-form": "Label",
    timeLimitationToPostShiftInDays: "Shift creation limit (in days)",
    timezone: "Timezone",
    slotConfig: "Slot Config",
    dealShift: "Negociate a shift",
    internalPool: "Internal pool",
    internalPoolCalendarBlockDays: "Internal pool calendar blocking period (days)",
    floor: "Floor",
    building: "Building",
    hasSmock: "Is outfit provided",
    hasFood: "Is food provided",
    minCaregiversPerShift: "Caregivers / shift (min)",
    bedsCapacity: "Number of beds",
    parking: "Parking",
    software: "Software",
    digicode: "Digicode"
};
