export enum CounterType {
    "Weekly" = 0,
    "Monthly" = 1,
    "Yearly" = 2
    // "YearlyBaseOnJoiningDate" = 3
}

export enum WorkerStatut {
    "Salarie" = 0,
    "Vacataire" = 1
}

export enum CounterLimitType {
    "WorkedMinutes" = 0,
    "Shifts" = 1
}

export const workerPossibleStatuts = [WorkerStatut.Salarie, WorkerStatut.Vacataire];

export const LimitTypeArray = [CounterLimitType.WorkedMinutes, CounterLimitType.Shifts];

export const WhichCounterArray = Object.keys(CounterType);
