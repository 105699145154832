import { useDispatch, useSelector } from "react-redux";
import { fetchCountersAction } from "../../store/action/counter.action";

import { ICounter, INormalizedCounters } from "../../../Model/Counter";
import { getModelsFromDict } from "../../modules/store/util";
import { getWhichCounterLabel, getStarterLabel, getCounterLimitTypeLabel } from "../../modules/jsx/counter";
import { EBackOfficeColumnName } from "../../constants/Column.constants";
import { fetchInstitutionGroupsAction } from "../../store/action/institutionGroup.action";
import { IInstitutionGroup } from "../../../Model/InstitutionGroup";
import { ILang } from "../../lang/Lang";
import React, { FC, useEffect, useState } from "react";
import { firstLetterUppercase } from "../../../modules/strings/case";
import { CounterType } from "../../../Constant/Counter.constant";
import moment from "moment";

import "./TableCounterStatus.scoped.scss";
import { DatetimeComponent, HubloModalComponent, SelectComponent } from "npm-medgo-components";
import { fetchCounters, insertCounter, updateCounter } from "../../service/counter.service";
import { workerPossibleStatuts } from "../../../Constant/Counter.constant";

const TableCounterContainer: FC<any> = ({ backOfficeTable }): JSX.Element => {
    const { title = "", subtitle = "" } = backOfficeTable;
    const lang = useSelector((centralState: any): ILang => centralState.language.lang);
    // @ts-ignore
    const displayedTitle = lang[title];
    // @ts-ignore
    const displayedSubitle = lang[subtitle];
    return (
        <div className="col-12 container-fluid h-100">
            <div className="table-title">{firstLetterUppercase(displayedTitle)}</div>
            <p>{firstLetterUppercase(displayedSubitle)}</p>
            <TableCounterStatusComponent />
        </div>
    );
};

export default TableCounterContainer;

function useInstitutionsToUpdate(): any {
    const { idInstitutions, selectedInstitutions, institutionGroupDict, idInstitutionGroups } = useSelector(
        (centralState: any): any => ({
            idInstitutions: centralState.institutions.ids,
            selectedInstitutions: centralState.institutions.selectedMulti,
            institutionGroupDict: centralState.institutionGroups.byId,
            idInstitutionGroups: centralState.institutionGroups.ids
        })
    );
    const dispatcher = useDispatch();
    useEffect((): void => {
        fetchInstitutionGroupsAction({
            query: { institution: idInstitutions },
            limit: 1000
        })(dispatcher);
    }, [selectedInstitutions]);
    const type1Group = getModelsFromDict<IInstitutionGroup>(institutionGroupDict, idInstitutionGroups).find(
        (e): boolean =>
            e.type === 1 &&
            e.institution
                .map((i): boolean => selectedInstitutions.indexOf(i) !== -1)
                .reduce((p, c): boolean => p || c, false)
    );
    const institutionIdsToUpdate =
        typeof type1Group !== "undefined" && type1Group.institution ? type1Group.institution : selectedInstitutions;
    return institutionIdsToUpdate;
}

function useFetchCounters(): any {
    const { counterDict, idCounters } = useSelector((centralState: any): {
        idCounters: number[];
        counterDict: INormalizedCounters[];
    } => ({
        idCounters: centralState.counter.ids,
        counterDict: centralState.counter.byId
    }));
    const dispatcher = useDispatch();
    const institutionIdsToFetch = useInstitutionsToUpdate();
    async function refreshCounters(): Promise<void> {
        return fetchCountersAction({
            query: {
                institution: institutionIdsToFetch
            },
            limit: 1000
        })(dispatcher);
    }
    useEffect((): void => {
        refreshCounters();
    }, [institutionIdsToFetch]);
    return [counterDict, idCounters, refreshCounters];
}

function useGetLang(): ILang {
    const { lang } = useSelector((centralState: any): {
        lang: ILang;
    } => ({
        lang: centralState.language.lang
    }));
    return lang;
}

function orderCountersBasedOnWhichCounter(a: ICounter, b: ICounter): 1 | -1 {
    return a.whichCounter > b.whichCounter ? 1 : -1;
}

function getStatusTable(counters: ICounter[]): ICounter[] {
    const dict = counters.reduce(
        (
            p: { [key: number]: { [key: number]: ICounter } },
            c: ICounter
        ): { [key: number]: { [key: number]: ICounter } } => {
            p[c.limitType][c.whichCounter] = c;
            return p;
        },
        {
            0: {
                0: {
                    limitType: 0,
                    whichCounter: 0,
                    starter: 1,
                    isActive: false,
                    id: -1,
                    institution: -1,
                    statut: 0
                },
                1: {
                    limitType: 0,
                    whichCounter: 1,
                    starter: 1,
                    isActive: false,
                    id: -2,
                    institution: -1,
                    statut: 0
                },
                2: {
                    limitType: 0,
                    whichCounter: 2,
                    starter: 1,
                    isActive: false,
                    id: -3,
                    institution: -1,
                    statut: 0
                }
                // 3: {
                //     limitType: 0,
                //     whichCounter: 3,
                //     starter: 1,
                //     isActive: false,
                //     id: -4,
                //     institution: -1,
                //     statut: 0
                // }
            },
            1: {
                0: {
                    limitType: 1,
                    whichCounter: 0,
                    starter: 1,
                    isActive: false,
                    id: -5,
                    institution: -1,
                    statut: 0
                },
                1: {
                    limitType: 1,
                    whichCounter: 1,
                    starter: 1,
                    isActive: false,
                    id: -6,
                    institution: -1,
                    statut: 0
                },
                2: {
                    limitType: 1,
                    whichCounter: 2,
                    starter: 1,
                    isActive: false,
                    id: -7,
                    institution: -1,
                    statut: 0
                }
                // 3: {
                //     limitType: 1,
                //     whichCounter: 3,
                //     starter: 1,
                //     isActive: false,
                //     id: -8,
                //     institution: -1,
                //     statut: 0
                // }
            }
        }
    );
    return Object.values(dict)
        .map(e => Object.values(e))
        .flat()
        .sort((a, b): 1 | -1 => (a.limitType > b.limitType ? 1 : orderCountersBasedOnWhichCounter(a, b)));
}

function getDayOfMonthOptions(whichCounter: CounterType, lang: ILang): any[] {
    if (whichCounter === CounterType.Weekly) {
        const arr = [];
        for (let i = 1; i <= 7; i++) {
            arr.push(i);
        }
        return arr.map((e): any => ({
            label: `${lang.weeklyStarter.replace(
                "__DAY",
                moment()
                    .day(e)
                    .format("dddd")
            )}`,
            value: e === 7 ? 0 : e
        }));
    } else {
        const arr = [];
        for (let i = 1; i < 32; i++) {
            arr.push(i);
        }
        return arr.map((e): any => ({
            label: `${e}`,
            value: e
        }));
    }
}

const ConfirmationModalContent: FC = (): JSX.Element => {
    const lang = useGetLang();
    return (
        <>
            <h3>{lang.confirmationCounterStatusTitle}</h3>
            <br />
            <p>{lang.confirmationCounterStatusText}</p>
        </>
    );
};

const ConfirmationModalFooter: FC<{ onConfirm: () => Promise<void>; onCancel: () => void; isLoading: boolean }> = ({
    onCancel,
    onConfirm,
    isLoading
}): JSX.Element => {
    const lang = useGetLang();
    return (
        <div className="container row">
            <div className="col-6">
                <button className="btn btn-default btn-block" onClick={onCancel}>
                    {lang.cancel}
                </button>
            </div>
            <div className="col-6">
                <button className="btn btn-success btn-block" onClick={onConfirm} disabled={isLoading}>
                    {isLoading ? <i className={"fas fa-spinner fa-spin"} /> : lang.confirm}
                </button>
            </div>
        </div>
    );
};

interface CounterStartSelectProps {
    newCounter: ICounter;
    setNewCounter: (counter: ICounter) => void;
}
interface TableCounterStatusTableFormLineProps extends CounterStartSelectProps {
    setShowConfirmationModal: (bool: boolean) => void;
    setIsForm: (bool: boolean) => void;
}

const StartSelectView: FC<CounterStartSelectProps> = ({ newCounter, setNewCounter }) => {
    const lang = useGetLang();
    return (
        <SelectComponent
            options={getDayOfMonthOptions(newCounter.whichCounter, lang)}
            value={getDayOfMonthOptions(newCounter.whichCounter, lang).filter(
                (e): boolean => e.value === newCounter.starter
            )}
            isMulti={false}
            className="is-fullwidth"
            onChange={(e): void => {
                setNewCounter({ ...newCounter, starter: parseInt(e.value as string, 10) });
            }}
        />
    );
};

const YearlyStartSelectView: FC<CounterStartSelectProps> = ({ newCounter, setNewCounter }) => {
    return (
        <DatetimeComponent
            hasFutureDates
            hasPastDates
            value={moment().set("dayOfYear", newCounter.starter)}
            onChange={(e): void => {
                setNewCounter({ ...newCounter, starter: e.dayOfYear() });
            }}
        />
    );
};

const TableCounterStatusTableFormLine: FC<TableCounterStatusTableFormLineProps> = ({
    newCounter,
    setNewCounter,
    setShowConfirmationModal,
    setIsForm
}) => {
    const lang = useGetLang();

    return (
        <tr>
            <td>{getCounterLimitTypeLabel(newCounter.limitType, lang)}</td>
            <td className="td-line">
                <SelectComponent
                    options={[
                        {
                            label: lang.enabled,
                            value: 1
                        },
                        {
                            label: lang.disabled,
                            value: 0
                        }
                    ]}
                    value={[
                        {
                            label: newCounter.isActive ? lang.enabled : lang.disabled,
                            value: newCounter.isActive ? 1 : 0
                        }
                    ]}
                    isMulti={false}
                    className="is-fullwidth"
                    onChange={(e): void => {
                        setNewCounter({ ...newCounter, isActive: e.value === 1 ? true : false });
                    }}
                />
            </td>
            <td>{getWhichCounterLabel(newCounter.whichCounter, lang)}</td>
            <td>
                {
                    {
                        [`${CounterType.Yearly}`]: (
                            <YearlyStartSelectView newCounter={newCounter} setNewCounter={setNewCounter} />
                        ),
                        [`${CounterType.Weekly}`]: (
                            <StartSelectView newCounter={newCounter} setNewCounter={setNewCounter} />
                        ),
                        [`${CounterType.Monthly}`]: (
                            <StartSelectView newCounter={newCounter} setNewCounter={setNewCounter} />
                        )
                    }[newCounter.whichCounter]
                }
            </td>
            <td>
                <i className="fa fa-times pointer fa-2x text-danger" onClick={(): void => setIsForm(false)} />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <i
                    className="fa fa-check pointer fa-2x text-success"
                    onClick={(): void => {
                        setShowConfirmationModal(true);
                    }}
                />
            </td>
        </tr>
    );
};

const TableCounterStatusTableDisplayLine: FC<{
    counter: ICounter;
    setIsForm: (bool: boolean) => void;
}> = ({ counter, setIsForm }): JSX.Element => {
    const lang = useGetLang();
    return (
        <tr>
            <td>{getCounterLimitTypeLabel(counter.limitType, lang)}</td>
            <td className="td-line">
                {counter.isActive ? (
                    <span className="badge badge-success badge-pill">{lang.enabled}</span>
                ) : (
                    <span className="badge badge-dark badge-pill">{lang.disabled}</span>
                )}
            </td>
            <td>{getWhichCounterLabel(counter.whichCounter, lang)}</td>
            <td>{getStarterLabel(counter.starter, counter.whichCounter, lang)}</td>
            <td>
                <i className="show-on-line-hover fa fa-pencil pointer" onClick={(): void => setIsForm(true)} />
            </td>
        </tr>
    );
};
const TableCounterStatusTableLine: FC<{
    counter: ICounter;
    handleUpdate: (newCounter: ICounter) => Promise<void>;
}> = ({ counter, handleUpdate }): JSX.Element => {
    const [isForm, setIsForm] = useState(false);
    const [newCounter, setNewCounter]: [ICounter, (counter: ICounter) => void] = useState(
        JSON.parse(JSON.stringify(counter))
    );
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    return isForm ? (
        <>
            <HubloModalComponent
                content={<ConfirmationModalContent />}
                title=""
                show={showConfirmationModal}
                size={"sm"}
                dialogClassName={"modal-dialog-centered"}
                footer={
                    <ConfirmationModalFooter
                        onCancel={(): void => {
                            setIsForm(!isForm);
                            setShowConfirmationModal(false);
                        }}
                        onConfirm={async (): Promise<void> => {
                            setIsLoading(true);
                            await handleUpdate(newCounter);
                            setIsForm(!isForm);
                            setIsLoading(false);
                            setShowConfirmationModal(false);
                        }}
                        isLoading={isLoading}
                    />
                }
            />
            <TableCounterStatusTableFormLine
                newCounter={newCounter}
                setNewCounter={setNewCounter}
                setShowConfirmationModal={setShowConfirmationModal}
                setIsForm={setIsForm}
            />
        </>
    ) : (
        <TableCounterStatusTableDisplayLine counter={counter} setIsForm={setIsForm} />
    );
};

async function updateCounterForInstitutions(newCounter: ICounter, institutionsToUpdate: number[]): Promise<void> {
    await Promise.all(
        institutionsToUpdate.map(
            async (e): Promise<void> => {
                await Promise.all(
                    workerPossibleStatuts.map(
                        async (statut): Promise<void> => {
                            const counters = await fetchCounters({
                                query: {
                                    institution: e,
                                    whichCounter: newCounter.whichCounter,
                                    statut,
                                    limitType: newCounter.limitType
                                }
                            });
                            if (counters?.length > 0) {
                                const counter = counters[0];
                                const counterId = counter.id;
                                const updatedCounter = Object.assign({}, counter, {
                                    isActive: newCounter.isActive,
                                    starter: newCounter.starter
                                });
                                await updateCounter(counterId, updatedCounter);
                            } else {
                                await insertCounter({
                                    id: -1,
                                    institution: e,
                                    isActive: newCounter.isActive,
                                    starter: newCounter.starter,
                                    statut,
                                    whichCounter: newCounter.whichCounter,
                                    limitType: newCounter.limitType
                                });
                            }
                        }
                    )
                );
            }
        )
    );
}

const TableCounterStatusComponent: FC = (): JSX.Element => {
    const lang = useGetLang();
    const [counterDict, idCounters, refreshCounters] = useFetchCounters();
    const counters = getStatusTable(getModelsFromDict<ICounter>(counterDict, idCounters));
    const institutionToUpdate = useInstitutionsToUpdate();
    return (
        <table>
            <thead>
                <tr>
                    <th>{lang[EBackOfficeColumnName.counterLimitType]}</th>
                    <th>{lang[EBackOfficeColumnName.isActive]}</th>
                    <th>{lang[EBackOfficeColumnName.whichCounter]}</th>
                    <th>{lang[EBackOfficeColumnName.starter]}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {counters.map(
                    (e): JSX.Element => (
                        <TableCounterStatusTableLine
                            key={e.id}
                            counter={e}
                            handleUpdate={async (counter: ICounter): Promise<void> => {
                                await updateCounterForInstitutions(counter, institutionToUpdate);
                                refreshCounters();
                            }}
                        />
                    )
                )}
            </tbody>
        </table>
    );
};
