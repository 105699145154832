import { useDispatch, useSelector } from "react-redux";

import { fetchInstitutionGroupsAction } from "../../store/action/institutionGroup.action";
import { FC, useEffect, useState } from "react";
import { firstLetterUppercase } from "../../../modules/strings/case";

import "./TableCounterLimit.scoped.scss";
import { HubloModalComponent } from "npm-medgo-components";
import { fetchCounters, updateCounter } from "../../service/counter.service";
import { LimitTypeArray, WhichCounterArray, WorkerStatut } from "../../../Constant/Counter.constant";
import React from "react";
import { fetchCountersAction } from "../../store/action/counter.action";

import { ICounter, INormalizedCounters } from "../../../Model/Counter";
import { getModelsFromDict } from "../../modules/store/util";
import { IInstitutionGroup } from "../../../Model/InstitutionGroup";
import { ILang } from "../../lang/Lang";
import { getLimitColumnDisplayName } from "../../modules/jsx/counter";

function displayTime(n: number | null): string {
    return typeof n === "number" ? `${Math.floor(n / 60)}h${n % 60 > 9 ? n % 60 : `${n % 60}0`}` : "-";
}

function displayNumberOfShifts(n: number | null): number | string {
    return typeof n === "number" ? n : "-";
}

function displayLimitHoursInputValue(inputValue: number | null): number {
    return inputValue ? Math.floor(inputValue / 60) : 0;
}

function displayLimitMinutesInputValue(inputValue: number | null): number {
    return inputValue ? inputValue % 60 : 0;
}

function displayLimitShiftsInputValue(inputValue: number | null): number {
    return inputValue ? inputValue : 0;
}

function computeOldValueInput(value: number | null): number {
    return !value || isNaN(value) ? 0 : value;
}

function getCountersMapped(counters: ICounter[]): any[] {
    const dict = counters
        .filter((e): boolean => e.isActive)
        .reduce(
            (p, c): any => {
                if (!p[c.statut]) {
                    p[c.statut] = {};
                }
                if (c.isActive) {
                    p[c.statut][`${c.whichCounter}_${c.limitType}`] = c.limitValue;
                }
                return p;
            },
            {
                0: {},
                1: {}
            } as any
        );
    return [dict[0], dict[1]].filter((e): boolean => e !== undefined);
}
function useInstitutionsToUpdate(): any {
    const { idInstitutions, selectedInstitutions, institutionGroupDict, idInstitutionGroups } = useSelector(
        (centralState: any): any => ({
            idInstitutions: centralState.institutions.ids,
            selectedInstitutions: centralState.institutions.selectedMulti,
            institutionGroupDict: centralState.institutionGroups.byId,
            idInstitutionGroups: centralState.institutionGroups.ids
        })
    );
    const dispatcher = useDispatch();
    useEffect((): void => {
        fetchInstitutionGroupsAction({
            query: { institution: idInstitutions },
            limit: 1000
        })(dispatcher);
    }, [selectedInstitutions]);
    const type1Group = getModelsFromDict<IInstitutionGroup>(institutionGroupDict, idInstitutionGroups).find(
        (e): boolean =>
            e.type === 1 &&
            e.institution
                .map((i): boolean => selectedInstitutions.indexOf(i) !== -1)
                .reduce((p, c): boolean => p || c, false)
    );
    const institutionIdsToUpdate =
        typeof type1Group !== "undefined" && type1Group.institution ? type1Group.institution : selectedInstitutions;
    return institutionIdsToUpdate;
}

function useGetCounters(): any {
    const { counterDict, idCounters } = useSelector((centralState: any): {
        idCounters: number[];
        counterDict: INormalizedCounters[];
    } => ({
        idCounters: centralState.counter.ids,
        counterDict: centralState.counter.byId
    }));
    return [counterDict, idCounters];
}

function useFetchCounters(): any {
    const dispatcher = useDispatch();
    const institutionIdsToFetch = useInstitutionsToUpdate();
    async function refreshCounters(): Promise<void> {
        return fetchCountersAction({
            query: {
                institution: institutionIdsToFetch
            },
            limit: 1000
        })(dispatcher);
    }
    useEffect((): void => {
        refreshCounters();
    }, [institutionIdsToFetch]);
    return [refreshCounters];
}

const TableCounterContainer: FC<{}> = ({}): JSX.Element => {
    const lang = useSelector((centralState: any): ILang => centralState.language.lang);
    const [counterDict, idCounters] = useGetCounters();
    const counters = getCountersMapped(getModelsFromDict(counterDict, idCounters));
    return Object.values(counters).filter((e): boolean => Object.keys(e).length > 0).length > 0 ? (
        <div className="col-12 container-fluid h-100">
            <div className="table-title">{firstLetterUppercase(lang.counterLimit)}</div>
            <p>{firstLetterUppercase(lang.onceLimitReachedWorkersWontBeAlerted)}</p>
            <TableCounterLimitComponent />
        </div>
    ) : (
        <span />
    );
};

export default TableCounterContainer;

function useGetLang(): ILang {
    const { lang } = useSelector((centralState: any): {
        lang: ILang;
    } => ({
        lang: centralState.language.lang
    }));
    return lang;
}

const ConfirmationModalContent: FC<{}> = (): JSX.Element => {
    const lang = useGetLang();
    return (
        <>
            <h3>{lang.confirmationCounterStatusTitle}</h3>
            <br />
            <p>{lang.confirmationCounterStatusText}</p>
        </>
    );
};

const ConfirmationModalFooter: FC<{ onConfirm: () => Promise<void>; onCancel: () => void; isLoading: boolean }> = ({
    onCancel,
    onConfirm,
    isLoading
}): JSX.Element => {
    const lang = useGetLang();
    return (
        <div className="container row">
            <div className="col-6">
                <button className="btn btn-default btn-block" onClick={onCancel}>
                    {lang.cancel}
                </button>
            </div>
            <div className="col-6">
                <button className="btn btn-success btn-block" onClick={onConfirm} disabled={isLoading}>
                    {isLoading ? <i className={"fas fa-spinner fa-spin"} /> : lang.confirm}
                </button>
            </div>
        </div>
    );
};

const TableCounterLimitTableFormCell: FC<{
    newCounterObject: any;
    whichCounter: number;
    limitType: number;
    setNewCounterObject: (newCounterObject: any) => void;
}> = ({ newCounterObject, whichCounter, limitType, setNewCounterObject }): JSX.Element =>
    limitType === 0 ? (
        <div className="row" style={{ maxHeight: "32px" }}>
            <input
                className="col form-control"
                style={{ maxHeight: "32px", maxWidth: "64px" }}
                key={`counter_${limitType}_${whichCounter}_HOUR`}
                name={`counter_${limitType}_${whichCounter}_HOUR`}
                value={displayLimitHoursInputValue(newCounterObject[`${whichCounter}_${limitType}`])}
                onChange={(e): void => {
                    // @ts-ignore
                    let newVal = parseInt(e.target.value);

                    if (typeof newVal === "number") {
                        const oldValue = computeOldValueInput(newCounterObject[`${whichCounter}_${limitType}`]);
                        newVal = oldValue - Math.floor(oldValue / 60) * 60 + newVal * 60;
                        setNewCounterObject({
                            ...newCounterObject,
                            [`${whichCounter}_${limitType}`]: newVal
                        });
                    }
                }}
            />{" "}
            <span className="align-middle" style={{ padding: "8px" }}>
                h
            </span>{" "}
            <input
                className="col form-control"
                style={{ maxHeight: "32px", maxWidth: "64px" }}
                key={`counter_${limitType}_${whichCounter}_MINUTE`}
                name={`counter_${limitType}_${whichCounter}_MINUTE`}
                value={displayLimitMinutesInputValue(newCounterObject[`${whichCounter}_${limitType}`])}
                onChange={(e): void => {
                    // @ts-ignore
                    let newVal = parseInt(e.target.value);
                    if (typeof newVal === "number") {
                        const oldValue = computeOldValueInput(newCounterObject[`${whichCounter}_${limitType}`]);
                        newVal = oldValue - (oldValue % 60) + newVal;
                        setNewCounterObject({
                            ...newCounterObject,
                            [`${whichCounter}_${limitType}`]: newVal
                        });
                    }
                }}
            />{" "}
        </div>
    ) : (
        <div className="row" style={{ maxHeight: "32px", marginLeft: "5px" }}>
            <input
                className="col form-control"
                style={{ maxHeight: "32px", maxWidth: "64px" }}
                key={`counter_${limitType}_${whichCounter}`}
                name={`counter_${limitType}_${whichCounter}`}
                value={displayLimitShiftsInputValue(newCounterObject[`${whichCounter}_${limitType}`])}
                onChange={(e): void => {
                    // @ts-ignore
                    const newVal = parseInt(e.target.value, 10);
                    if (typeof newVal === "number") {
                        setNewCounterObject({
                            ...newCounterObject,
                            [`${whichCounter}_${limitType}`]: newVal
                        });
                    }
                }}
            />
        </div>
    );

const TableCounterLimitTableFormLine: FC<{
    newCounterObject: any;
    setNewCounterObject: (counter: any) => void;
    statut: number;
    setShow: (bool: boolean) => void;
    setIsForm: (bool: boolean) => void;
}> = ({ newCounterObject, setNewCounterObject, setShow, setIsForm, statut }): JSX.Element => {
    const lang = useGetLang();
    return (
        <tr>
            <td>
                {statut === WorkerStatut.Salarie
                    ? lang.salarie
                    : statut === WorkerStatut.Vacataire
                    ? lang.vacataire
                    : "-"}
            </td>

            {newCounterObject["3_1"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={3}
                        limitType={1}
                    />
                </td>
            ) : null}
            {newCounterObject["3_0"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={3}
                        limitType={0}
                    />
                </td>
            ) : null}
            {newCounterObject["2_1"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={2}
                        limitType={1}
                    />
                </td>
            ) : null}
            {newCounterObject["2_0"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={2}
                        limitType={0}
                    />
                </td>
            ) : null}
            {newCounterObject["1_1"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={1}
                        limitType={1}
                    />
                </td>
            ) : null}
            {newCounterObject["1_0"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={1}
                        limitType={0}
                    />
                </td>
            ) : null}
            {newCounterObject["0_1"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={0}
                        limitType={1}
                    />
                </td>
            ) : null}
            {newCounterObject["0_0"] !== undefined ? (
                <td>
                    <TableCounterLimitTableFormCell
                        newCounterObject={newCounterObject}
                        setNewCounterObject={setNewCounterObject}
                        whichCounter={0}
                        limitType={0}
                    />
                </td>
            ) : null}
            <td>
                <i className="fa fa-times pointer fa-2x text-danger" onClick={(): void => setIsForm(false)} />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <i
                    className="fa fa-check pointer fa-2x text-success"
                    onClick={(): void => {
                        setShow(true);
                    }}
                />
            </td>
        </tr>
    );
};

const TableCounterLimitTableDisplayLine: FC<{
    countersObject: ICountersObject;
    statut: number;
    setIsForm: (bool: boolean) => void;
}> = ({ countersObject, statut, setIsForm }): JSX.Element => {
    const lang = useGetLang();
    return (
        <tr>
            <td>
                {statut === WorkerStatut.Salarie
                    ? lang.salarie
                    : statut === WorkerStatut.Vacataire
                    ? lang.vacataire
                    : "-"}
            </td>
            {countersObject["3_1"] !== undefined ? <td>{displayNumberOfShifts(countersObject["3_1"])}</td> : null}
            {countersObject["3_0"] !== undefined ? <td>{displayTime(countersObject["3_0"])}</td> : null}
            {countersObject["2_1"] !== undefined ? <td>{displayNumberOfShifts(countersObject["2_1"])}</td> : null}
            {countersObject["2_0"] !== undefined ? <td>{displayTime(countersObject["2_0"])}</td> : null}
            {countersObject["1_1"] !== undefined ? <td>{displayNumberOfShifts(countersObject["1_1"])}</td> : null}
            {countersObject["1_0"] !== undefined ? <td>{displayTime(countersObject["1_0"])}</td> : null}
            {countersObject["0_1"] !== undefined ? <td>{displayNumberOfShifts(countersObject["0_1"])}</td> : null}
            {countersObject["0_0"] !== undefined ? <td>{displayTime(countersObject["0_0"])}</td> : null}
            <td>
                <i className="show-on-line-hover fa fa-pencil pointer" onClick={(): void => setIsForm(true)} />
            </td>
        </tr>
    );
};
interface ICountersObject {
    [key: string]: number;
}
const TableCounterLimitTableLine: FC<{
    statut: number;
    countersObject: ICountersObject;
    handleUpdate: (newCounter: ICounter) => Promise<void>;
}> = ({ statut, countersObject, handleUpdate }): JSX.Element => {
    const [isForm, setIsForm] = useState(false);
    const [newCountersObject, setNewCountersObject]: [any, (newCounter: any) => void] = useState(
        JSON.parse(JSON.stringify(countersObject))
    );
    useEffect(() => {
        setNewCountersObject(JSON.parse(JSON.stringify(countersObject)));
    }, [countersObject]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    return isForm ? (
        <>
            <HubloModalComponent
                content={<ConfirmationModalContent />}
                title=""
                show={show}
                size={"sm"}
                dialogClassName={"modal-dialog-centered"}
                footer={
                    <ConfirmationModalFooter
                        onCancel={(): void => {
                            setIsForm(!isForm);
                            setShow(false);
                        }}
                        onConfirm={async (): Promise<void> => {
                            setIsLoading(true);
                            await handleUpdate(newCountersObject);
                            setIsForm(!isForm);
                            setIsLoading(false);
                            setShow(false);
                        }}
                        isLoading={isLoading}
                    />
                }
            />
            <TableCounterLimitTableFormLine
                newCounterObject={newCountersObject}
                statut={statut}
                setNewCounterObject={setNewCountersObject}
                setShow={setShow}
                setIsForm={setIsForm}
            />
        </>
    ) : (
        <TableCounterLimitTableDisplayLine countersObject={countersObject} setIsForm={setIsForm} statut={statut} />
    );
};

async function updateCounterForInstitutions(
    newCounterObject: any,
    statut: number,
    institutionsToUpdate: number[]
): Promise<void> {
    await Promise.all(
        institutionsToUpdate.map(
            async (e): Promise<void> => {
                await Promise.all(
                    LimitTypeArray.map(
                        async (limitType: number): Promise<void> => {
                            await Promise.all(
                                WhichCounterArray.map(
                                    async (whichCounterString): Promise<void> => {
                                        const whichCounter = parseInt(whichCounterString);
                                        if (typeof newCounterObject[`${whichCounter}_${limitType}`] === "number") {
                                            const counters = await fetchCounters({
                                                query: {
                                                    institution: e,
                                                    whichCounter,
                                                    statut,
                                                    limitType
                                                }
                                            });
                                            if (counters?.length > 0) {
                                                const counter = counters[0];
                                                const counterId = counter.id;
                                                const updatedCounter = Object.assign({}, counter, {
                                                    limitValue:
                                                        newCounterObject[`${whichCounter}_${limitType}`] === 0 ||
                                                        isNaN(newCounterObject[`${whichCounter}_${limitType}`])
                                                            ? null
                                                            : newCounterObject[`${whichCounter}_${limitType}`]
                                                });
                                                await updateCounter(counterId, updatedCounter);
                                            }
                                        }
                                    }
                                )
                            );
                        }
                    )
                );
            }
        )
    );
}

const TableCounterLimitHead: FC<{ countersMapped: any[] }> = ({ countersMapped }): JSX.Element => {
    const lang = useGetLang();
    countersMapped.sort((a, b): 1 | -1 => (Object.keys(a).length > Object.keys(b).length ? 1 : -1));
    return (
        <thead>
            <tr>
                <th>{lang.workerType}</th>
                {countersMapped?.length > 0 && countersMapped[0]
                    ? Object.keys(countersMapped[0])
                          .sort()
                          .reverse()
                          .map((e): JSX.Element => <th key={e}>{getLimitColumnDisplayName(e, lang)}</th>)
                    : null}
                <th></th>
            </tr>
        </thead>
    );
};
const TableCounterLimitComponent: FC<{}> = (): JSX.Element => {
    const [counterDict, idCounters] = useGetCounters();
    const [refreshCounters] = useFetchCounters();
    const countersMapped = getCountersMapped(getModelsFromDict<ICounter>(counterDict, idCounters));
    const institutionToUpdate = useInstitutionsToUpdate();

    return (
        <table>
            <TableCounterLimitHead countersMapped={countersMapped} />
            <tbody>
                {countersMapped
                    .filter((e): boolean => Object.keys(e).length > 0)
                    .map(
                        (e, i): JSX.Element => (
                            <TableCounterLimitTableLine
                                key={i}
                                statut={i}
                                countersObject={e}
                                handleUpdate={async (counter: ICounter): Promise<void> => {
                                    await updateCounterForInstitutions(counter, i, institutionToUpdate);
                                    await refreshCounters();
                                }}
                            />
                        )
                    )}
            </tbody>
        </table>
    );
};
