import { ILabelHref } from "npm-medgo-components";
import { Equality } from "npm-hublo-toolbox";

import { firstLetterUppercase } from "../../modules/strings/case";
import { IPropsApp } from "../App";
import { IAdmin } from "../../Model/Admin";
import { INormalizedInstitutionOptions } from "../../Model/InstitutionOption";
import { INormalizedLevels } from "../../Model/Level";
import { Permission, isAllowedTo } from "../modules/authorization/permission";

/**
 * @param container this is the container you want to have
 */

function generateTokensString() {
    const token = localStorage.getItem("token");
    const ssoAccessToken = localStorage.getItem("SSOAccessToken");
    let res = token && token !== "" ? `?token=${token}` : null;
    if (ssoAccessToken && ssoAccessToken !== "") {
        res = `${res ? `${res}&` : "?"}SSOAccessToken=${ssoAccessToken}`;
    }
    return res ?? "";
}

function getDropdownItems(
    this: any,
    profile: string,
    myInstitutions: string,
    myNotifications: string,
    logout: string,
    myConfig: string,
    level: INormalizedLevels,
    me: IAdmin
): ILabelHref[] {
    const dropdownItems: ILabelHref[] = [
        {
            label: firstLetterUppercase(profile),
            href: process.env.REACT_APP_MONOREPO_BASE_URL?.concat("/hr-management/me/profile") ?? "/me/profile"
        }
    ];

    if (
        isAllowedTo({
            institutionIds: me.institutionIds,
            permission: Permission.SEE_PRO_INSTITUTION_PAGE,
            permissions: me.permissions
        })
    ) {
        dropdownItems.push({
            label: firstLetterUppercase(myInstitutions),
            href: "/me/institutions"
        });
    }

    dropdownItems.push({
        label: firstLetterUppercase(myNotifications),
        href: "/me/notifications"
    });

    if (
        isAllowedTo({
            institutionIds: me.institutionIds,
            permission: Permission.SEE_PRO_INSTITUTION_CONFIG_PAGE,
            permissions: me.permissions
        })
    ) {
        dropdownItems.push({
            label: firstLetterUppercase(`${myConfig}`),
            href: "/"
        });
        dropdownItems.push({
            label: "Go to URL",
            onClick: () => {
                const url = prompt("Write your link below :", "https://app.hubpreprod.com/");
                if (url) {
                    window.location.href = url;
                }
            }
        });
    }

    dropdownItems.push({
        label: firstLetterUppercase(logout),
        onClick: this.logout
    });

    return dropdownItems;
}

export function implementHookGetDropdownItems(container: IImplementHookGetMenus) {
    container.getDropdownItems = () => {
        const profile = container.props.lang.profile;
        const myInstitutions = container.props.lang.myInstitutions;
        const myNotifications = container.props.lang.myNotifications;
        const logout = container.props.lang.logout;
        const myConfig = container.props.lang.myConfig;
        const level = container.props.level;
        const me = container.props.me;
        return getDropdownItems.bind(container)(profile, myInstitutions, myNotifications, logout, myConfig, level, me);
    };
}

function getMenuItems(
    proMissions: string,
    proVacataires: string,
    proContracts: string,
    contractDemo: string,
    me: IAdmin,
    level: INormalizedLevels,
    currentLang: string,
    idInstitutions: number[],
    institutionsOptions: INormalizedInstitutionOptions,
    dashboard: string
): ILabelHref[] {
    const tokensString = generateTokensString();
    const menuItems: ILabelHref[] = [
        {
            label: proMissions.toUpperCase(),
            href: process.env.REACT_APP_MEDGO_URL ? process.env.REACT_APP_MEDGO_URL.concat("/pro/missions2") : "/"
        },
        {
            label: proVacataires.toUpperCase(),
            href: process.env.REACT_APP_MEDGO_URL ? process.env.REACT_APP_MEDGO_URL.concat("/pro/vacataires2") : "/"
        }
    ];
    if (
        Object.keys(institutionsOptions).length !== 0 &&
        idInstitutions.length === Object.keys(institutionsOptions).length
    ) {
        const institutionsHasContract = idInstitutions.map((idInstitution: number): boolean => {
            return institutionsOptions[idInstitution].hasContract;
        });
        const isDemoContractAllowed =
            institutionsHasContract.indexOf(true) === -1 && currentLang === "fr" ? true : false;
        const isContractAllowed =
            institutionsHasContract.indexOf(true) !== -1 &&
            (me.contractLevel === 9 || me.contractLevel === 10 || me.level === 1);
        if (isContractAllowed) {
            menuItems.push({
                label: proContracts.toUpperCase(),
                href: process.env.REACT_APP_CONTRACT_URL ? process.env.REACT_APP_CONTRACT_URL.concat(tokensString) : "/"
            });
        }
        if (isDemoContractAllowed) {
            menuItems.push({
                label: contractDemo.toUpperCase(),
                onClick: () =>
                    window.open(process.env.SERVICE_CONTRACT_DEMO_URL ?? "https://hublo.com/fr/contract", "_blank")
            });
        }
        if (
            !Equality.isNullOrUndefined(me) &&
            !Equality.isNullOrUndefined(me.level) &&
            !Equality.isNullOrUndefined(me.isStatisticAllowed) &&
            !Equality.isNullOrUndefined(level) &&
            (me.isStatisticAllowed ||
                isAllowedTo({
                    institutionIds: me.institutionIds,
                    permission: Permission.SEE_STATISTICS_PAGE,
                    permissions: me.permissions
                }))
        ) {
            menuItems.push({
                label: dashboard.toUpperCase(),
                href: process.env.REACT_APP_DASHBOARD_URL
                    ? process.env.REACT_APP_DASHBOARD_URL.concat(tokensString)
                    : "/"
            });
        }
    }
    return menuItems;
}

export function implementHookGetMenuItems(container: IImplementHookGetMenus) {
    container.getMenuItems = () => {
        const proMissions = container.props.lang.proMissions;
        const proVacataires = container.props.lang.proVacataires;
        const proContracts = container.props.lang.proContracts;
        const contractDemo = container.props.lang.contractDemo;
        const me = container.props.me;
        const level = container.props.level;
        const currentLang = container.props.currentLang;
        const idInstitutions = container.props.idInstitutions;
        const institutionsOptions = container.props.institutionsOptions;
        const dashboard = container.props.lang.dashboard;
        return getMenuItems.bind(container)(
            proMissions,
            proVacataires,
            proContracts,
            contractDemo,
            me,
            level,
            currentLang,
            idInstitutions,
            institutionsOptions,
            dashboard
        );
    };
}

export interface IImplementHookGetMenus {
    getDropdownItems: () => ILabelHref[];
    getMenuItems: () => ILabelHref[];
    props: IPropsApp;
}
